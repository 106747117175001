import { useState, useEffect } from "react";

const InputPlaceholder = (
  props: {
    inputRef: React.RefObject<HTMLInputElement>;
    onChange: () => void;
    onKeyDown: (event: any) => void;
    onFocus?: () => void;
    className: string;
  }
) => {
  const {
    inputRef,
    onChange,
    onKeyDown,
    onFocus
  } = props;

  const [placeholder, setPlaceholder] = useState("");
  const [placeholderIndex, setPlaceholderIndex] = useState(0);

  const placeholders = ["Type something here...", "NEET", "NCERT books", "IIT JEE", "Newton's laws of motion", "Maths Live Class"];
  useEffect(() => {
    let i = 0;
    const typingInterval = setInterval(() => {
      const placeholderText = placeholders[placeholderIndex];
      if (i <= placeholderText.length) {
        setPlaceholder(placeholderText.slice(0, i));
        i++;
      } else {
        clearInterval(typingInterval);
        setTimeout(() => {
          setPlaceholderIndex((placeholderIndex + 1) % placeholders.length);
        }, 2000);
      }
    }, 100);
    return () => clearInterval(typingInterval);
  }, [placeholderIndex]);

  const extraProps: any = {};
  if(onFocus){
    extraProps.onFocus = onFocus;
  }

  return <input id="search-input" name='search-input'
    ref={inputRef} type="text"
    placeholder={placeholder}
    className={props.className}
    onChange={onChange}
    onKeyDown={onKeyDown} 
    {...extraProps}
  />
}

export default InputPlaceholder;