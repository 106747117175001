import FaCamera from "../SVG/Fontawesome/FaCamera";
import { offCanvasInitialState, updateOffCanvasView } from "@/store/slices/offCanvas";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";

const Camera = (props: any) => {
  const dispatch = useDispatch();

  const handleCameraClick = (e: any) => {
    e.preventDefault();
    if (isMobile) {
      dispatch(updateOffCanvasView({ name: "CAMERA", data: { CAMERA: { ...offCanvasInitialState.data.CAMERA } } }));
      return;
    }
    // TODO open file picker
  };

  return (
    <span className={`flex justify-center items-center ${props.color ?? "text-white"}`}>
      <button name="camera-btn" id="camera-btn" className="flex justify-center items-end space-x-1" onClick={handleCameraClick}>
        <FaCamera className="w-5 h-5 text-xl transition duration-150 ease-in-out cursor-pointer" />
      </button>
    </span>
  );
};

export default Camera;
